.testimonial-card__column {
  width: 100%;

  .testimonial-card {
    &:last-child {
      margin-bottom: 3.375rem;
    }
  }

  @media only screen and (min-width: 60em){
    width: 50%;
    margin-bottom: 1rem;

    .testimonial-card {
      &:last-child {
        margin-bottom: 0rem;
      }
    }
    
    &:first-child {
      padding-right: 1rem;
    }

    &:last-child {
      padding-left: 1rem;

      .testimonial-card {
        // the below is so that in desktop the cards in each column alternate
        // from different card styles but in mobile they just alternate on even children
        &:nth-child(odd) {
          background-color: #FAFAFA; 
          border-color: #F0EFEF;
    
          .testimonial-card__copy {
            p {
              color: $midnight-charcoal;
            }
          }
    
          .testimonial-card__footer {  
            .testimonial-card__footer__organization-details {
              p {
                color: $inclusion-grape;
              }
            }
          }
        }

        &:nth-child(even) {
          background-color: #F6EFFB; 
          border-color: #F4E0FE;
          
    
          .testimonial-card__copy {
            p {
              color: $inclusion-grape;
            }
          }
    
          .testimonial-card__footer {  
            .testimonial-card__footer__organization-details {
              p {
                color: $midnight-charcoal;
              }
            }
          }
        }
      }
    }
  }
}

.testimonial-card {
  // Override blockquote styles
  margin-inline: 0;
  margin-block: 0;

  position: relative;
  padding: 2.5rem 2rem;
  border-radius: 5px;
  border: 1px solid #F4E0FE;
  background-color: #F6EFFB;
  margin-bottom: 2.44rem;

  &__quotations {
    position: absolute;
    left: 2rem;
    top: -0.875rem;
    width: 2.5rem;
  }

  &__copy {
    margin-bottom: 1rem;

    p {
      font-size: 1rem;
      line-height: 1.5rem;
      color: $inclusion-grape;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }

    &__organization-details {
      max-width: calc(200% / 3);

      &__author {
        font-size: 1rem;
        color: $midnight-charcoal;
      }
    
      &__organization {
        font-size: 0.875rem;
        font-weight: 600;
        color: $midnight-charcoal;
      }
    }

    &__logo {
      padding-top: 1rem;

      img {
        max-height: 2.5rem;
      }
    }
  }

  &:nth-child(even) {
    background-color: #FAFAFA; 
    border-color: #F0EFEF;

    .testimonial-card__copy {
      p {
        color: $midnight-charcoal;
      }
    }

    .testimonial-card__footer {  
      .testimonial-card__footer__organization-details {
        p {
          color: $inclusion-grape;
        }
      }
    }
  }

  @media only screen and (min-width: 60em){
    .testimonial-card__footer {
      flex-direction: row;

      .testimonial-card__footer__logo {
        align-self: center;
        width: 50%;
        padding-top: 0;
        text-align: right;
      }
    }
  }
}

.testimonial-card--carousel {
  // Override blockquote styles
  margin-inline: 0;
  margin-block: 0;

  position: relative;
  padding: 2.5rem 2rem;
  border-radius: 5px;
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .testimonial-card__copy {
    p {
      color: $midnight-charcoal;
    }
  }

  .testimonial-card__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }

    .testimonial-card__footer__organization-details {
      .testimonial-card__footer__organization-details__author {
        color: $inclusion-grape;
      }

      .testimonial-card__footer__organization-details__organization {
        color: $midnight-charcoal;
      }
    }

    @media only screen and (min-width: 30em) {
      align-items: center;

      &__logo {
        align-self: flex-end;
        padding-top: 0;
        padding-left: 1rem;
      }
    }
  }
}