.Button-header {
  background: $inclusion-grape;
  color: #ffffff;
  border: 3px solid #ffffff;
  padding: 0.75rem 1.5rem;
  border-radius: 0.31rem;
  text-decoration: none;
  letter-spacing: 0.5px;
  transition: all 0.24s ease;
  white-space: nowrap;
}

.Button-header:hover {
  background: #ffffff;
  color: $inclusion-grape;
  border-color: $inclusion-grape;
}
