.banner {
  background: #aedff2;
  padding: 1.69rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 998;

  .banner__text {
    line-height: 100%;
    font-weight: 400;
    font-size: 1.1rem;
    color: #001059;
    text-align: center;
    margin: 0 0 0.38rem 0;
  }

  .banner__text--bold {
    font-weight: 700;
    margin-bottom: 0.38rem;
  }

  .banner__cta {
    border-radius: 0.375rem;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(80, 148, 178, 0.25);
    color: #000;
    text-align: center;
    font-family: Helvetica;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 1rem */
    text-decoration: none;
    padding: 0.625rem;
    gap: 0.625rem;
    margin-top: 1.25rem;

    &:focus,
    &:hover {
      border: 1px solid #0026ca;
    }
  }
}

@media only screen and (min-width: 30em) {
  .banner--blank-space {
    padding-top: 11.25rem;
  }

  .banner {
    padding: 1.69rem 0.5rem;
  }
}

@media only screen and (min-width: 43em) {
  .banner--blank-space {
    padding-top: 9.25rem;
  }
}

@media only screen and (min-width: 60em) {
  .banner--blank-space {
    padding-top: 5.5rem;
  }

  .header--banner-blank-space-l {
    top: 5.5rem;
  }

  .banner {
    padding: 1.69rem 4rem;
    flex-direction: row;
    justify-content: space-between;

    .banner__text {
      text-align: left;
    }

    .banner__cta {
      width: 10.9375rem;
      margin: 0;
    }
  }
}
