@import "_colors.scss";
@import "_variables.scss";

.link {
  transition: all 0.24s ease;
}

.hidden-until-expanded {
  &[visible=true] {
    display: block;
  }
}

.shadow-hover:hover {
  transition: all 0.24s ease;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.2);
}

a {
  color: $inclusion-grape;
}
