@import "_colors";
@import "_forms";

.w-inherit {
  width: inherit;
}
.content-box {
  box-sizing: content-box;
}
.max-height-0 {
  max-height: 0;
  transition: max-height 0.25s cubic-bezier(0, 1, 0, 1);
}
.max-height-huge {
  max-height: 5000px;
  transition: max-height 1.5s ease-in-out;
}

.mw-23rem {
  max-width: 23rem;
}

.mw5-plus {
  max-width: 30rem;
}
.w-10rem-ns {
  @media only screen and (min-width: 30em) {
    width: 10rem;
  }
}
.h-13rem-ns {
  @media only screen and (min-width: 30em) {
    height: 13rem;
  }
}
.w-15rem-ns {
  @media only screen and (min-width: 30em) {
    width: 15rem;
  }
}
.w-6rem {
  width: 6rem;
}
.w-6rem-ns {
  @media only screen and (min-width: 30em) {
    width: 6rem;
  }
}
@media only screen and (min-width: 30em) and (max-width: 60em) {
  .w-12rem-m {
    width: 12rem;
  }
}
.object-fit-cover {
  object-fit: cover;
}

.w2-75 {
  width: 2.75rem;
}
.w2-875 {
  width: 2.875rem;
}
.w10-625rem {
  width: 10.625rem;
}
.w--fit-content {
  width: fit-content;
}
.h2-875 {
  height: 2.875rem;
}
.h7rem {
  height: 7rem;
}
.h10rem {
  height: 10rem;
}
.pt0-35rem {
  padding-top: 0.35rem;
}
.pt3-375rem {
  padding-top: 3.375rem;
}

.pt7rem {
  padding-top: 7rem;
}

.pb1-5rem {
  padding-bottom: 1.5rem;
}

.pb7rem {
  padding-bottom: 7rem;
}

.ph1-5rem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pr1-5rem {
  padding-right: 1.5rem;
}

.pv1-5rem {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.mb1-5rem {
  margin-bottom: 1.5rem;
}

.mh1-5rem {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.m-auto {
  margin: auto;
}
.mw-20rem {
  @media only screen and (max-width: 30em) {
    max-width: 20rem;
  }
}
.pa-0-8rem {
  padding: 0.8rem;
}
.pa-1-5rem-ns {
  @media only screen and (min-width: 30em) {
    padding: 1.5rem;
  }
}

.pr1-5rem-ns {
  @media only screen and (min-width: 30em) {
    padding-right: 1.5rem;
  }
}

.mw-24rem-m {
  @media only screen and (min-width: 30em) {
    max-width: 24rem;
  }
}

hr.white-hr,
.white-hr {
  border-top: 0.04rem solid white;
}

.grey-hr {
  border-top: 1px solid #dcdfe6;
}

.bg-white-nl {
  @media only screen and (max-width: 60em) {
    background-color: $white;
  }
}

.mw7-5 {
  max-width: 50rem;
}

.top-4 {
  top: 4.3rem;
}

.bw-0-06rem {
  border-width: 0.06rem;
}
.b--dark-gray {
  border-color: $dark-gray;
}
.b--inclusion-grape-hover {
  border-color: $inclusion-grape;
}
.w-third-laptop {
  @media only screen and (min-width: 47.5em) and (max-width: 90em) {
    width: 33.3%;
  }
}

.max-h-16rem {
  @media only screen and (max-width: 30em) {
    max-height: 16rem;
  }
}

.max-h-35rem-m {
  @media only screen and (min-width: 30em) {
    max-height: 35rem;
  }
}

.max-h-40rem {
  @media only screen and (min-width: 30em) {
    max-height: 40rem;
  }
}

.min-w-11rem {
  min-width: 11rem;
}

.min-h-3-4 {
  min-height: 3.4rem;
}

.dn-laptop {
  @media only screen and (min-width: 60em) and (max-width: 66em) {
    display: none;
  }
}
.lh-2-5rem {
  line-height: 2.5rem;
}

.flex-grow {
  flex: 1 0 auto;
  min-width: 0; /* 1 */
  min-height: 0; /* 1 */
}

.break-word {
  overflow-wrap: break-word;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.list-item {
  display: list-item;
}

.tracked-s {
  letter-spacing: 0.03125em;
}

@media only screen and (min-width: 60em) {
  .top-100-l {
    top: 100%;
  }

  .top-4-l {
    top: 4.3rem;
  }

  .shadow-0-l {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .bg-seashell-l {
    background-color: $seashell;
  }

  .h13rem-l {
    height: 13rem;
  }

  .max-h-3-43rem {
    max-height: 3.43rem;
  }

  .max-w-12-375rem-l {
    max-width: 12.375rem;
  }

  .mt26rem-l {
    margin-top: 26rem;
  }

  .pl5rem-l {
    padding-left: 5rem;
  }

  .max-h-25-5rem {
    max-height: 25.5rem;
  }

  .mh9-81-rem-l {
    margin-left: 9.81rem;
    margin-right: 9.81rem;
  }

  // HACK: This class matches an elements height to the screen's height minus the header and footer.
  //       A far more elegant solution worked without hard-coded heights using flex however it does
  //       not work in Safari and IE11 due to problems with height:100% and a parent whose height is
  //       variable.
  .full-body-height-l {
    height: calc(100vh - 11rem);
  }
}
@media only screen and (min-width: 60em) and (max-width: 86em) {
  .h2-laptop {
    height: 2rem;
  }
  .f5-laptop {
    font-size: 1rem;
  }
  .mt3-laptop {
    margin-top: 1rem;
  }
  .mb3-laptop {
    margin-bottom: 1rem;
  }
  .mt0-laptop {
    margin-top: 0;
  }
  .f-content-body-laptop {
    font-size: 1.15rem;
  }
  .f-content-quote-laptop {
    font-size: 1.5rem;
  }
}
