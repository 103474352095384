.Button-text {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: color .15s ease-in;
  color: $dark-gray;
  margin: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  text-decoration: none;

  &:hover {
    color: $inclusion-grape;
  }
}