.pt1-5rem {
  padding-top: 1.5rem;
}
.pb1-5rem {
  padding-bottom: 1.5rem;
}
.ph1-5rem {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.pv1-5rem {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
