@import "_colors.scss";

.resize-none {
  resize: none;
}

.field-padding {
  padding: 0.75rem 0.5rem;
}

.asterisk-after {
  &:after {
    content: "*";
    color: $main_red;
  }
}

.box-radio-label{
  transition: all ease-in-out 0.1s;

  &:hover {
    background-color: gray;
    border-color: gray;
    color: white;
  }
}

.box-radio-input {
  appearance: none;

  &:checked + .box-radio-label {
    background-color: $inclusion-grape;
    border-color: $inclusion-grape;
    color: white;
  }
}

.spin-animation {
  animation:spin 2s linear infinite;
}

.bulge-animation {
  animation: bulge 0.3s ease-in-out 1;
}

.shake-animation {
  animation: shake 0.3s ease-in-out 1;
}

@keyframes spin { 100% { transform:rotate(360deg); } }

@keyframes bulge {
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes shake {
  25% { transform: translateX(5px); }
  50% { transform: translateX(-3px); }
  75% { transform: translateX(1px); }
  100% { transform: translateX(0); }
}
