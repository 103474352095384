@import "./variables";

.border-black {
  border: 1px black;
}

.bg-washed-main-blue {
  background: rgba($main-blue, 0.08)
}

.bg-washed-inclusion-grape {
  background: rgba($inclusion-grape, 0.08)
}

.bg-washed-pink-red {
  background: rgba($pink-red, 0.08)
}

.bg-washed-dark-violet {
  background: rgba($dark-violet, 0.08)
}

.bg-washed-dark-gray {
  background: rgba($dark-gray, 0.08)
}

.pink-red-hover:hover {
  color: $pink-red;
}

.dark-violet-hover:hover {
  color: $dark-violet;
}

.main-blue-hover:hover {
  color: $main-blue;
}

.inclusion-grape-hover:hover {
  color: $inclusion-grape;
}

.inclusion-grape-hover-darkened:hover {
  color: darken($inclusion-grape, 0.5);
}

.pink-red {
  color: $pink-red;
}

.dark-violet {
  color: $dark-violet;
}

.main-blue {
  color: $main-blue;
}

.inclusion-grape {
  color: $inclusion-grape;
}

.bg-cream {
  background-color: $cream;
}
.bg-white, .bg-white-hover:hover {
  background-color: white;
}
.bg-main-black {
  background-color: $main-black;
}
.bg-main-blue {
  background-color: $main-blue;
}
.bg-inclusion-grape {
  background-color: $inclusion-grape;
}
.bg-dark-blue {
  background-color: $dark-blue;
}

.bg-light-gray {
  background-color: $light-gray;
}

.bg-transparent {
  background-color: rgba(0,0,0,0);
}
.bg-seashell {
  background-color: $seashell;
}
.bg-gray {
  background-color: $gray;
}

.bg-violet, .bg-violet-hover:hover {
  background-color: $violet;
}

.bg-dark-navy-blue{
  background-color: $dark-navy-blue;
}

.bg-sky-blue{
  background-color: $sky-blue;
}
.tc-main-blue {
  color: $main-blue;
}
.tc-gray {
  color: $gray;
}

.tc-main-gray, .main-gray-hover:hover {
  color: $main-gray;
}
.tc-main-pink {
  color: $main-pink;
}
.tc-main-blue {
  color: $main-blue;
}
.tc-inclusion-grape {
  color: $inclusion-grape;
}
.tc-dark-gray{
  color: $dark-gray;
}
.tc-mid-gray{
  color: $mid-gray;
}

.tc-dark-blue{
  color: $dark-blue;
}

.tc-main-pink-hover:hover {
  color: $main-pink;
}

.tc-main-blue-hover:hover {
  color: $main-blue;
}

.tc-dark-navy-blue-hover:hover{
  color: $dark-navy-blue;
}

.tc-sky-blue-hover:hover{
  color: $sky-blue;
}
.black-hover:hover {
  color: black;
}

.cyan-hover:hover, .cyan-hover:hover .cyan-hover {
  color: $cyan;
}

.cyan-bg-hover:hover {
  background-color: $cyan;
}

.white-bg-hover:hover {
  background-color: white;
}

.b--main-pink {
  border-color: $main-pink;
}

.b--dark-seashell {
  border-color: $dark-seashell;
}

.b--white {
  border-color: white;
}

.b--main-blue, .b--main-blue-hover:hover {
  border-color: $main-blue;
}

.bg-transparent {
  background-color: rgba(0,0,0,0);
}
