.participant-logos {
  margin-top: 3rem;
  margin-bottom: 2rem;

  &__title {
    margin-left: 1.88rem;
    margin-right: 1.88rem;
    text-align: center;
    margin-bottom: 3rem;
    color: $inclusion-grape;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: -0.03281rem;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    width: 12rem;
    height: 4.5rem;
    padding-bottom: 1rem;

    &:nth-child(odd) {
      padding-right: 0.375rem;
    }

    &:nth-child(even) {
      padding-left: 0.375rem;
    }
  }

  @media only screen and (min-width: 30em) {
    margin-top: 6rem;

    .participant-logos__logo {
      cursor: pointer;
      padding-right: 0.375rem;
      padding-left: 0.375rem;
    } 
  }

  @media only screen and (min-width: 60em) {
    margin: 6rem 5.5rem 4rem 5.5rem;

    .participant-logos__title {
      margin-bottom: 2.5rem;
    }

    .participant-logos__logo {
      padding-bottom: 2rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}