@import "_colors.scss";

.gray-underline {
  @media only screen and (min-width: 60em) {
    width: 28rem;
  }
  @media only screen and (min-width: 25em) {
    width: 14rem;
  }
  width: 7rem;
  height: 0.5rem;
  background-color: $light-gray-2;
}

header {
  font-family: "Montserrat";
  font-weight: 700;
  position: sticky;
  top: 0;
}

.header__nav-links--position {
  margin-top: 3.1rem;
  position: absolute;
  left: 0;
}

.header__nav-links--dimensions {
  max-height: calc(100vh - 17rem);
}

#skip-nav a:focus {
  clip: auto;
  left: 0;
  top: 4.3rem;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 10px 0;
  border: 2px solid $dark-violet;
  border-left: none;
  border-right: none;
  text-align: center;
  font-weight: bold;
  color: $dark-violet;
  background-color: white;
  z-index: 99999;
}

nav .active {
  font-weight: 600;
  color: $inclusion-grape;
}

.closed {
  @media only screen and (max-width: 60em) {
    display: none;
  }
}

.gray-divider {
  width: 40%;
  height: 0px;
  border-bottom: 1px solid $light-gray-2;
}

.below {
  top: 100%;
}

.mobile-form {
  height: 80vh !important;    
}

@media only screen and (min-width: 60em) {
  .header__nav-links--position {
    margin-top: 0;
    position: static;
  }
  .mobile-form {
    height: auto !important;
  }
}



