$fa-font-path: "~@fortawesome/fontawesome-pro/webfonts";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-pro/scss/solid.scss";
@import "~@fortawesome/fontawesome-pro/scss/regular.scss";
@import "~@fortawesome/fontawesome-pro/scss/brands.scss";
@import "~@fortawesome/fontawesome-pro/scss/light.scss";
@import "~plyr/dist/plyr";
@import "../stylesheets/custom_plyr.css";

// If adding stylesheets add between tachyons import and custom tachyons due to css hierarchy issues
@import "../stylesheets/less_specific_custom_tachyons.scss";
@import "~tachyons-sass/tachyons.scss";
@import "../stylesheets/banner.scss";
@import "../stylesheets/buttons.scss";
@import "../stylesheets/button-header.scss";
@import "../stylesheets/button_text.scss";
@import "../stylesheets/collaborator.scss";
@import "../stylesheets/header.scss";
@import "../stylesheets/text.scss";
@import "../stylesheets/variables.scss";
@import "../stylesheets/colors.scss";
@import "../stylesheets/courses.scss";
@import "../stylesheets/carousel.scss";
@import "../stylesheets/fonts.scss";
@import "../stylesheets/media_query_tachyons.scss";
@import "../stylesheets/custom_tachyons.scss";
@import "../stylesheets/enquire_banner.scss";
@import "../stylesheets/participant_logos.scss";
@import "../stylesheets/modal.scss";
@import "../stylesheets/testimonial_card.scss";
