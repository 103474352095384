.enquire-banner {
  background-color: $inclusion-grape;
  padding: 2.5rem 1.44rem 4.38rem 1.63rem;

  &__title {
    color: white;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3rem;
    letter-spacing: -0.03281rem;
    margin-bottom: 1.5rem;
  }

  &__copy {
    color: white;
    margin-bottom: 4.38rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    opacity: 0.88;
  }

  &__button {
    min-width: fit-content;
    width: fit-content;
    padding: 1rem 2rem;
    background-color:  $periwinkle-blue;
    border-radius: 3px;
    text-decoration: none;
    font-weight: 700;
    transition: all 0.24s;

    &:hover {
      background-color: white;
    }
  }
}

@media only screen and (min-width: 73em){
  .enquire-banner {
    position: relative;
    min-height: 17.5rem;
    padding: 0;
  
    &__title {
      position: absolute;
      left: 11.62rem;
      top: 4rem;
      margin-bottom: 0;
    }
  
    &__copy {
      margin-bottom: 0;
      position: absolute;
      left: 11.81rem;
      top: 8.37rem;
      // Adjust width of copy based on screen - left to start of copy - end of copy to the right of the screen
      // This is so the space between the copy and the button remains constant
      width: calc(100% - 39.56rem); 
    }
  
    &__button {
      position: absolute;
      right: 11.75rem;
      top: 7rem;
    }
  }
}