$slick-font-path: "~slick-carousel/slick/fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "~slick-carousel/slick/" !default;
$slick-arrow-color: black !default;
@import "~slick-carousel/slick/slick.scss";
@import "./variables";

// Slick Class Overrides
.slick-dots {
  pointer-events:none;
  display: flex;
  justify-content: center;
  padding: 2.5rem 0.5625rem;
  position: absolute;
  width: 100%;

  & li {
    pointer-events: all;
    flex: flex;
    align-items: center;
  }

  & li i {
    font-size: 1rem;
  }

  & li i:hover, & li i:focus {
    opacity: 0.4;
  }

  & li.slick-active i {
    color: $inclusion-grape;
    opacity: 1;
  }

  & li i:hover, & li i:focus {
    font-size: 0.75rem;
  }
}

.slick-next {
  right: -1rem;
}

.slick-prev {
  left: -1rem;
}

// .slick-next {
//   right: calc(50% - 8rem);
// }

// .slick-prev {
//   left: calc(50% - 8rem);
// }

.slick-next, .slick-prev {
  border: none;
  top: calc(50% - 0.75rem);
  // top: calc(100% + 2.5rem);
  z-index: 10;
  cursor: pointer;
  position: absolute;
  transition: all ease-in-out 0.2s;
  font-size: 1rem;

  & i {
    position: relative;
    left: 0;
    right:0;
    top:0;
    bottom: 0;
  }

  &:before {
    content:'';
  }
  &:hover {
    opacity: 1;
  }
}

.slick-slide {
  max-width: 50vw;
  margin-right: 1rem;
  margin-left: 1rem;
  padding-top: 1rem;
}

.slick-slider {
  margin: 0 9.875rem 2.5rem;

  @media only screen and (max-width: 60em) {
    margin: 0 1rem 2.5rem;
  }
}

.slick-track{
  display: flex;
  align-items: stretch; 
}

// HACK: Forces carousel items to keep equal heights
.slick-slide
{
    height: auto;
    & > div {
      height: 100%;
      display: flex;
      align-items: stretch;
    }   
}

.carousel-arrow {
  display: flex; 
  justify-content: center;
  align-items: center;
  background-color: transparent;

  i {
    font-weight: 900;
    color: $inclusion-grape;
  }

  &:hover {
    i {
      color: darken($inclusion-grape, 10%);
    }
  }
}