$main-red: #d70101;
$main-blue: #0026CA;
$main-black: #000000;
$main-gray: #333333;
$main-pink: #e10050;
$dark-blue: #17142a;
$dark-blue-2: #00355a;
$cyan: #0ed1c7;
$cream: #f7f7f7;
$gray: #697684;
$light-gray: #d8d8d8;
$light-gray-2: #dcdfe6;
$mid-gray: #50555B;
$dark-gray: #333a42;
$charcoal: #464646;
$sage-green: #0c8385;
$periwinkle: #9AABDC;
$dark-periwinkle: #5765c5;;
$radioactive-green: #c2d63d;
$sky-blue: #07a0de;
$vibrant-violet: #6a16b0;
$vibrant-pink: #a70283;;
$dirt-orange: #cf6c3c;
$leafy-green: #008250;
$sea-green: #229f80;
$white: #ffffff;
$off-near-white: #f4f4f4;
$seashell: #f1f1f1;
$dark-seashell: #dbdbdb;
$violet: #4f4ca4;
$pink-red: #e10050;
$dark-violet: #4f4ca4;
$fuschia:  #c40d7d;
$dark-navy-blue: #0c0a3e;
$sky-blue-2: #0080d9;
$sbs-purple: #59358C;
$inclusion-grape: #6B1D93;
$periwinkle-blue: #B5C7F7;
$midnight-charcoal: #202020;
