.Collaborator {
  display: flex;
  align-items: flex-start;
}

.Collaborator-title {
  margin: 0 0 1.5rem 0;
}

.Collaborator-image {
  display: block;
  width: 12rem;
  margin-bottom: 1rem;
}

.Collaborator-description {
  line-height: 1.6;
  margin-bottom: 2rem;
}

.gray-bottom-border {
  border-bottom: solid 1px #dcdfe6;
}
