@import "./variables";

.awb-course-theme {
  .quote_color {
    color: $inclusion-grape;
  }
  .card_bg_color {
    background-color: $inclusion-grape;
  }
  .points_bg_color {
    background-color: white;
  }
  .points_text_color {
    color: $dark-gray;
  }
  .icon_color {
    color: $inclusion-grape;
  }
}

.age-course-theme {
  .quote_color {
    color: $sage-green;
  }
  .card_bg_color {
    background-color: $sage-green;
  }
  .points_bg_color {
    background-color: white;
  }
  .points_text_color {
    color: $dark-gray;
  }
  .icon_color {
    color: $inclusion-grape;
  }
}
.atsi-course-theme {
  .quote_color {
    color: $dirt-orange;
  }
  .card_bg_color {
    background-color: $leafy-green;
  }
  .points_bg_color {
    background-color: white;
  }
  .points_text_color {
    color: $dark-gray;
  }
  .icon_color {
    color: $inclusion-grape;
  }
}
.core-course-theme {
  .quote_color {
    color: $dark-periwinkle;
  }
  .card_bg_color {
    background-color: $sea-green;
  }
  .points_bg_color {
    background-color: white;
  }
  .icon_color {
    color: $inclusion-grape;
  }
  .points_text_color {
    color: $dark-gray;
  }
}
.cultural-course-theme {
  .quote_color {
    color: $dark-periwinkle;
  }
  .card_bg_color {
    background-color: $charcoal;
  }
  .points_bg_color {
    background-color: white;
  }
  .points_text_color {
    color: $dark-gray;
  }
  .icon_color {
    color: $inclusion-grape;
  }
}
.disability-course-theme {
  .quote_color {
    color: $sky-blue-2;
  }
  .card_bg_color {
    background-color: $sky-blue;
  }
  .points_bg_color {
    background-color: white;
  }
  .points_text_color {
    color: $dark-gray;
  }
  .icon_color {
    color: $inclusion-grape;
  }
}
.gender-course-theme {
  .quote_color {
    color: $sky-blue;
  }
  .card_bg_color {
    background-color: $sky-blue;
  }
  .points_bg_color {
    background-color: white;
  }
  .points_text_color {
    color: $dark-gray;
  }
  .icon_color {
    color: $inclusion-grape;
  }
}
.lgbtiq-course-theme {
  .quote_color {
    color: $vibrant-violet;
  }
  .card_bg_color {
    background-color: $vibrant-violet;
  }
  .points_bg_color {
    background-color: white;
  }
  .points_text_color {
    color: $dark-gray;
  }
  .icon_color {
    color: $inclusion-grape;
  }
}
