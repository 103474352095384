@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&display=swap');

:root {
  font-size: 16px;
  font-family: "Montserrat", "Open Sans", "Helvetica", "Arial", sans-serif;
  font-style: normal;
}

h1, button {
  font-family: "Montserrat", "Open Sans", "Helvetica", "Arial", sans-serif;
}

h1 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  @media (min-width: 60em) {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  @media (min-width: 60em) {
    font-size: 2.5rem;
  }
}

h3 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 1.5rem;
  @media (min-width: 60em) {
    font-size: 2rem;
  }
}

h4 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  margin-bottom: 1.5rem;
}

.p-l {
  font-size: 1.13rem;
  line-height: 1.6;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 0 0 1rem 0;
  color: $dark-gray;
}

i {
  transition: all 0.24s ease;
}

ul {
  padding-left: 1.5rem;
  color: $dark-gray;
}
